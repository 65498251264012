import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SQLiteService } from 'src/app/services/sqlite.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {

  @Input() job_id;
  @Input() component_name;
  @Output() load : EventEmitter<any> = new EventEmitter();
  
  pageLimit:number = 6;
  page:number = 0;
  totalScanned:number = 0;

  constructor(
    private sqlite: SQLiteService,
    public data: DataService,
  ) {

  }

  ngOnInit() {
   
  }

  async ngAfterViewInit() {
    await this.sqlite.db.query(`SELECT count(*) as count FROM ${this.component_name} job_id = ${this.job_id}`).then((res) => {
      this.totalScanned = res.values[0].count;
      if (this.totalScanned > 0) {
        this.page = Math.floor(this.totalScanned / this.pageLimit);
        if (this.totalScanned % this.pageLimit === 0) {
          this.page--;
        }
      }
    });

    this.load.emit({ page: this.page, component_name: this.component_name, totalScanned: this.totalScanned });
  }

  updateScanCount(update) {
    if (update.status) {
      switch (update.operator) {
        case "++":
          this.totalScanned++;
          this.page = Math.floor(this.totalScanned / this.pageLimit);
          if (this.totalScanned % this.pageLimit === 0) this.page--; 
          break;
        case "--":
          this.totalScanned--;
          if (this.totalScanned % this.pageLimit === 0){
            this.page = Math.floor(this.totalScanned / this.pageLimit) - 1;
          }
          break;
      }
    }
    this.load.emit({ page: this.page, component_name: this.component_name, totalScanned: this.totalScanned });
  }


  changePage(direction:'back'|'forward') {                                 
    if(direction == 'back') this.page--;
    else this.page++;
    this.load.emit({page: this.page, component_name: this.component_name, totalScanned: this.totalScanned});
  }

}
