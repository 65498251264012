import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';

import { DateTime } from 'luxon';
import { DataService } from 'src/app/services/data.service';
import { Events } from 'src/app/services/events.service';
import { SQLiteService } from 'src/app/services/sqlite.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { CartonScanProduct } from 'src/app/models/survey.model';


@Component({
  selector: 'carton-scans',
  templateUrl: 'carton-scans.html',
  styleUrls: ['./carton-scans.scss']
})
export class CartonScansComponent {

  @Input() carton_scans;
  @Input() fieldSelected;
  @Input() visibleSections;
  @Input() content;
  @Input() job_id;
  @Input() loaded;

  @Output() selectField : EventEmitter<any> = new EventEmitter()
  @Output() setSection : EventEmitter<any> = new EventEmitter()
  @Output() saveForm : EventEmitter<any> = new EventEmitter()

  @ViewChild("products_cartons") products_cartons: PaginationComponent;
  @ViewChild("manual_products_cartons") manual_products_cartons: PaginationComponent;

  products_query = "carton_scans WHERE type ='products' AND "
  manual_products_query = "carton_scans WHERE type ='manual_products' AND scantime IS NOT NULL AND "

  products: CartonScanProduct[] = []
  manual_products: CartonScanProduct[] = []

  products_page: number = 0;
  manual_products_page: number = 0;

  pageLimit:number = 6;
  
  damaged:boolean = false;
  description : string;

  last_scanned : any;

  custom_carton = { 
    CartonNo: '', 
    reason: '', 
    image: '' 
  }
  viewManualCartonAdd:boolean = false;

  constructor(
    public data: DataService,
    private events: Events,
    private sqlite: SQLiteService
  ) {
    this.events.subscribe('carton_scans_added', (data) => {
      if(data=='manual_products'){
        this.manual_products_cartons.updateScanCount({status: true, operator : "++"});
      }
      else{
        this.products_cartons.updateScanCount({status: false});
      }
    });
  }

  ngOnInit() {
    
  }

  async load(event) {
    if(event && event.component_name == this.products_query){
      this.products_page = event.page;
      this.loadProducts(this.products_query, this.products_page, "products");
    }
    else if(event && event.component_name == this.manual_products_query){
      this.manual_products_page = event.page;
      this.loadProducts(this.manual_products_query, this.manual_products_page, "manual_list");
    }
  }

  async loadProducts(table, page, list){
    await this.sqlite.db.query(`SELECT * FROM ${table} job_id = ${this.job_id} LIMIT ${this.pageLimit} OFFSET ${page * this.pageLimit}`).then((res)=>{
      list == "products" ? this.products = res.values : this.manual_products = res.values
    })
  }
  
  selectCarton(p, type) {
    console.log('selected carton', p);
    let index = p.id
    this.carton_scans.active = { index, type }
    this.damaged = p.damaged == 1;
    this.description = p.description;
    
    let el:any = document.getElementById("carton_scans-section");
    if(el) this.content.scrollToPoint(0, el.offsetTop);
  }
  
  getActive(key) {
    let {type, index} = this.carton_scans.active;
    let carton = this[type].find(item => item.id === index);
    if(!this[type]) return "";
    if(!carton) return "";
    if(!carton[key]) return "";
    return carton[key]
  }

  async setCartonDamage() {
    console.log('active carton', this.carton_scans.active)
    if(!this.carton_scans.active) return
    let {index, type} = this.carton_scans.active;
    // let carton = this[type].find(item => item.id == index);
    if(index){
      if(!this.damaged) this.description = "";
      let values = [this.damaged, this.description, index];
      await this.sqlite.db.query(`UPDATE carton_scans SET damaged=?,description=? where id=?`, values);
      
      //update local variable so that if the user selects this product again, it tracks the data properly
      for(let i in this[type]) {
        if(this[type][i].id == index) {
          this[type][i].damaged = this.damaged;
          this[type][i].description = this.description;
        }
      }

      this.damaged = false;
      this.description = ''
      this.carton_scans.active = false
    }
    else return;
  }

  updateKey(event, key){
    key == 'damages' ? this.damaged = event.detail.value : this.description = event.detail.value 
  }

  async setCartonImage() {
    await this.data.checkPerms("CAMERA")
    await this.data.checkPerms("WRITE_EXTERNAL_STORAGE")

    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      width: 600,
      source: CameraSource.Camera,
      direction: CameraDirection.Rear,
    });

    if(image != null) {
      this.custom_carton.image = `data:image/${image.format};base64,${image.base64String}`;
    }
  }

  async addCustomCarton(){
    if(!this.custom_carton.CartonNo) {
      this.data.alert(this.data.text['cartons-no_number'])
      return 
    }
    if(!this.custom_carton.reason) {
      this.data.alert(this.data.text['cartons-no_reason'])
      return;
    }

    await this.sqlite.db.query(`INSERT INTO carton_scans (job_id, type, image, reason, CartonNo, Status, scantime) VALUES (${this.job_id},'manual_products','${this.custom_carton.image}','${this.custom_carton.reason}' ,'${this.custom_carton.CartonNo}',2,'${DateTime.local().toFormat("y-MM-dd HH:mm:ss")}')`);
    const query = `SELECT last_insert_rowid() AS lastId;`
    let result = await this.sqlite.db.query(query);
    this.carton_scans.active = {
      index: result.values[0].lastId,
      type: 'manual_products'
    }
    this.manual_products_cartons.updateScanCount({status: true, operator : "++"});
    this.custom_carton = { CartonNo: '', reason: '', image: '' }
    this.viewManualCartonAdd = false;

    this.saveForm.emit();
  }

  startManualCarton() {
    this.carton_scans.active = false;
    this.viewManualCartonAdd = true;
  }

  cancelCustomCarton() {
    this.viewManualCartonAdd = false;
    this.carton_scans.active = {}
    this.custom_carton = {
      CartonNo: '',
      reason: '',
      image: ''
    }
  }

  async deleteManualProduct(id){
    //Need logic to check if damage value is not assigned should not be deleted 
    await this.sqlite.db.query(`DELETE FROM carton_scans where id = ${id}`);
    this.manual_products_cartons.updateScanCount({status: true, operator : "--"});
  }

}
