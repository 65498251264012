import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { DateTime } from 'luxon';
import { SQLiteService } from './sqlite.service';
import { Product } from '../models/survey.model';


@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  server_url = "https://sqlsrv.claires.app/";
  base_url = this.server_url + '_endpoints/eu/app/';

  job_id = null;
  sync_date : string;

  constructor(
    public http: HttpClient,
    private data: DataService,
    private sqlite: SQLiteService
  ) { }

  init(job_id: number) {
    this.job_id = job_id;
  }

  async fetchDailyProductList() {
    let today = DateTime.local().minus({ days: 1 }).toFormat("y-MM-dd");
    let dailyProductsDate = (await this.sqlite.db.query(`SELECT variable_value from variables where variable_name = 'daily_products_date'`))?.values[0]?.variable_value;
    let dailyProductsCount = (await this.sqlite.db.query(`SELECT count(*) as count from daily_products`)).values[0].count;
    console.log('Current count of daily products', dailyProductsCount);
    if (!dailyProductsDate || dailyProductsDate != today) {
      try {
        await this.sqlite.db.query('DELETE FROM daily_products');
        this.sync_date = today
        this.sqlite.db.query(`INSERT OR REPLACE INTO variables (variable_name, variable_value) VALUES ('daily_products_date','${today}')`);
        const startTime = performance.now();
        let result: any = await this.get("load/product_list");
        const endTime = performance.now();
        const timeTaken = endTime - startTime;
        console.log(`Daily Product Get Request Time : ${timeTaken/1000} seconds`);
        if (result.data) {
          const startTime = performance.now();
          let query = `INSERT OR IGNORE INTO daily_products (LongCode, ShortCode, Description) VALUES `;
          let values = [];
          await this.sqlite.db.beginTransaction(); // Start a transaction
          for (let row of result.data) {
            let q = `('${row.LongCode}','${row.ShortCode}','${row.Description}')`;
            values.push(q);
            
            if (values.length >= 10000) {
              await this.sqlite.db.query(query + values.join(','));
              values = [];
            }
          }
  
          // Commit the transaction
          await this.sqlite.db.commitTransaction();
          const endTime = performance.now();
          const timeTaken = endTime - startTime;
          console.log(`Daily Product Insertion Time : ${timeTaken/1000} seconds`);
        }
      } catch (error) {
        // Rollback the transaction in case of an error
        await this.sqlite.db.rollbackTransaction();
        console.error('Error inserting data:', error);
      }
    }
    else this.sync_date = dailyProductsDate;
  }
 

  /**
   * 
   * @param scanned_code accepts a barcode (type string) to be looked up in the daily_products list
   * @returns returns an object with valid:boolean and the last sync date stored
   */

  async searchProduct(scanned_code: string): Promise<Product> {
    try {
      let dailyProductsDate = (await this.sqlite.db.query(`SELECT variable_value from variables where variable_name = 'daily_products_date'`))?.values[0]?.variable_value;
      let dailyProducts = (await this.sqlite.db.query(`SELECT count(*) as count from daily_products`))?.values[0]?.count;
  
      let response: Product = { valid: false, sync_date: "Not Synced" };
  
      if (dailyProductsDate) response.sync_date = dailyProductsDate;
  
      if (dailyProducts === 0) {
        return response;
      }
  
      let productFound = (await this.sqlite.db.query(`SELECT * from daily_products where ShortCode = ? or LongCode = ?`, [scanned_code, scanned_code]))?.values;
  
      if (productFound && productFound.length > 0) {
        return { ...response, ...productFound[0], valid: true };
      } else {
        return response;
      }
    } catch (error) {
      console.error("Error in searchProduct:", error);
      return { valid: false, sync_date: "Not Synced" };
    }
  }

  async fetchInventoryData() {
    let departmentCount = await this.sqlite.db.query(`SELECT count(*) as count from departments`);
    console.log('Department count:', departmentCount);
    if (departmentCount.values && departmentCount.values[0].count === 0) {
      try {
        const startTime = performance.now();
        let result: any = await this.get("load/stock_inventory_data");
        const endTime = performance.now();
        const timeTaken = endTime - startTime;
        console.log(`Department Get Request Time : ${timeTaken/1000} seconds`);
        if (result) {
          const startTime = performance.now();
          let dept_query = `INSERT OR IGNORE INTO departments (department, description, status) VALUES `;
          let class_query = `INSERT OR IGNORE INTO classes (class, department_id, description, status) VALUES `;
          let zone_query = `INSERT OR IGNORE INTO zones (zone, status) VALUES `;
          let dept_values = [];
          let class_values = [];
          let zone_values = [];

          for (let row of result.departments) {
            let q = `(${row.Department}, '${row.Description}', ${row.Status})`;
            dept_values.push(q);

            for (let c of row.class_list) {
              let q = `('${c.Class}', ${row.Department}, '${c.Description}', ${c.Status})`;
              class_values.push(q);
            }
          }

          for (let row of result.zones) {
            let q = `('${row.Zone}', ${row.Status})`;
            zone_values.push(q);
          }

          if (dept_values.length > 0) {
            await this.sqlite.db.query(dept_query + dept_values.join(','));
          }

          if (class_values.length > 0) {
            await this.sqlite.db.query(class_query + class_values.join(','));
          }

          if (zone_values.length > 0) {
            await this.sqlite.db.query(zone_query + zone_values.join(','));
          }
          const endTime = performance.now();
          const timeTaken = endTime - startTime;
          console.log(`Department Insertion Time : ${timeTaken/1000} seconds`);
        }
      } catch (error) {
        console.error('Error fetching or inserting data:', error);
      }
    }

  }

  async lookup(table: 'markdowns'| 'cycle_count' | 'carton_scans' | 'damages' | 'perpetual_inventory' | 'master_stock', code: string | number, reason?: string) {
    if (!this.job_id) {
      console.warn(`Unable to look up "${code}" in table "${table}" - job ID is not set`);
      return false;
    }

    let query = `select * from ${table} where job_id = ${this.job_id}`;

    if(table == 'carton_scans') query += ` and CartonNo = '${code}' and type = 'products'` 
    else query += ` and (ShortCode = '${code}' OR LongCode = '${code}')`;

    // Add condition for 'damages' table with reason
    if (table === 'damages' && reason) query += ` AND ReasonCode = '${reason}'`;

    let result = await this.sqlite.db.query(query);

    if (result && result.values) {
      if (result.values.length > 1) {
        console.log('Lookup complete - found ' + result.values.length + ' results:', result.values)
        return result.values
      }
      else if (result.values.length == 1) {
        console.log('Lookup complete - found a result:', result.values[0])
        return result.values[0];
      }
    }
    return false
  }

  damagesFormatting(products){
    const productsByReasonCode = {};

    products.forEach(item => {
      const { ReasonCode } = item;

      if (!productsByReasonCode[ReasonCode]) {
        productsByReasonCode[ReasonCode] = [];
      }

      productsByReasonCode[ReasonCode].push(item);
    });

    return productsByReasonCode;
  }

  post(endpoint, data) {
    return new Promise((res, rej) => {
      this.data.setToken();
      this.http.post(this.base_url + endpoint, data, this.data.httpOptions).subscribe((ret: any) => {
        console.log(endpoint, ret);
        res(ret);
      });
    });
  }

  get(endpoint) {
    return new Promise((res, rej) => {
      this.data.setToken();
      this.http.get(this.base_url + endpoint, this.data.httpOptions).subscribe((ret: any) => {
        console.log(endpoint, ret);
        res(ret);
      });
    });
  }
}

