import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScannerService } from 'src/app/services/scanner.service';
import { DataService } from 'src/app/services/data.service';
import { SQLiteService } from 'src/app/services/sqlite.service';
import { Events } from 'src/app/services/events.service';
import { DateTime } from 'luxon';
import { TransfersObject, TransfersStoresList } from 'src/app/models/survey.model';


@Component({
  selector: 'transfers',
  templateUrl: 'transfers.html',
  styleUrls: ['./transfers.scss'],
})
export class TransfersComponent implements OnInit {
    objectKeys = Object.keys;

    @Input() transfers: TransfersObject;
    @Input() validTransferStores;
    @Input() transferReasonCodes;
    @Input() job_id;
    @Input() store_id;
    @Input() fieldSelected;
    @Input() visibleSections;
    @Input() loaded;
    @Output() selectField : EventEmitter<any> = new EventEmitter()
    @Output() setSection : EventEmitter<any> = new EventEmitter()
    @Output() saveForm : EventEmitter<any> = new EventEmitter()


    storeResults = [];
    lastSelectedStoreType:string = '';
    searchVal:string = '';
    searchLoading:boolean = false;
    temp:any
    editing = {
        storeIndex: 0,
        index: 0,
        reason: "",
    }

    deselectedStores = {
        from_store: true,
        to_store: true
    }

    list: TransfersStoresList[] = [];

    constructor(
        public data: DataService,
        public scanner: ScannerService,
        private sqlite: SQLiteService,
        private events: Events
    ) {
        this.events.subscribe('transfers_added', () => this.load());
    }

    async ngOnInit() {
        if(this.transfers && !this.transfers.invalid) {
            this.transfers.invalid = {
                from_store: false,
                to_store: false,
            }
        }
        
        await this.load();
    }

    async load() {
        let result = await this.sqlite.db.query(`select * from transfers where job_id = ${this.job_id}`);
        let storeList = {};
        //loop through all and sort by from/to store and reason
        for(let row of result.values) {
            let key = row.from_store+'-'+row.to_store;
            if(!storeList[key]) storeList[key] = {from_store: row.from_store, to_store: row.to_store, reasons: {}}
            if(!storeList[key].reasons[row.transfer_reason]) storeList[key].reasons[row.transfer_reason] = []
            storeList[key].reasons[row.transfer_reason].push(row);
        }
        this.list = Object.values(storeList);
        console.log('loaded transfers list', this.list)
    }
    
    //transfers
    searchStores(storeType, query) {
        this.transfers[storeType] = query;
        this.lastSelectedStoreType = storeType;
        this.searchVal = query;
        this.searchLoading = true;
        this.storeResults = [];
        if(storeType == "from_store") this.transfers.to_store = this.store_id;
        if(storeType == "to_store") this.transfers.from_store = this.store_id;

        //new local search functionality
        this.storeResults = this.validTransferStores.filter((val) => val.includes(query))
        this.searchLoading = false;

        /*
        //OLD API FUNCTIONALITY
        //1 second delay allows the user to type a few numbers before searching
        setTimeout(()=>{
            if(query !== this.searchVal) return;

            this.scanner.post('search/stores/'+storeType, {
                from_store: this.transfers.from_store, 
                to_store: this.transfers.to_store
            }).then((result:any)=>{
                this.searchLoading = false;
                if(result.data) this.storeResults = result.data;
                if(result.message) this.data.alert(result.message);
            })
        }, 1000)
        */
    }


    async setStore(store) {
        if(this.lastSelectedStoreType != '') {
            this.transfers.invalid[this.lastSelectedStoreType] = false;
            this.transfers[this.lastSelectedStoreType] = store;
        }
    }


    selectStoreField(storeType) {
        this.deselectedStores[storeType] = false;
        this.selectField.emit(true)
    }


    deselectField(storeType) {
        //timeout is implemented so that lastSelectedStoreType can be leveraged by setStore()
        setTimeout(()=>{
            this.deselectedStores[storeType] = true;
            this.transfers.invalid[storeType] = false;

            if(!this.validTransferStores.includes(this.transfers[storeType]) && this.transfers[storeType] != this.store_id) {
                this.transfers.invalid[storeType] = true;
            }
            
            this.lastSelectedStoreType = ""
            this.storeResults = [];
        }, 400);
        
        this.selectField.emit(false)
    }


    async saveTransfer() {
        // Need some work here
        let og = this.list[this.editing.storeIndex].reasons[this.editing.reason][this.editing.index];
        let t = this.temp;

        console.log('job_id', this.job_id);
        console.log('temp', t)
        console.log('og', og)

        // let product = await this.scanner.searchProduct(t.LongCode);
        let product: any = (await this.sqlite.db.query(`SELECT * from daily_products where LongCode = '${t.LongCode}'`))?.values[0];
        //if not valid, display error
        if(product) {
            // console.log('query', `UPDATE transfers SET ShortCode = '${t.ShortCode}', LongCode = '${t.LongCode}', quantity = ${t.quantity}, from_store = '${t.from_store}', to_store = '${t.to_store}', transfer_reason = '${t.transfer_reason}' WHERE id = ${og.id}`)
            
            //sqlite update row
            this.sqlite.db.query(`UPDATE transfers SET ShortCode = '${product.ShortCode}', LongCode = '${product.LongCode}', quantity = ${t.quantity} WHERE job_id = ${this.job_id} and id = ${t.id})`);
    
            this.list[this.editing.storeIndex].reasons[this.editing.reason][this.editing.index] = {...this.temp};
    
            this.temp = false
            this.editing = { storeIndex: 0,index: 0,reason: ""};
        }
        else {
            this.data.alert(`${this.data.text["svy-product"]} "${t.LongCode}" ${this.data.text["svy-invalid_as_of"]} `)
            return 
        }

        this.saveForm.emit();
    }


    editTransferItem(storeIndex, reason, index) {
        this.editing = {storeIndex, reason, index}
        this.temp = {...this.list[storeIndex].reasons[reason][index]};
        console.log('editing transfer item', this.temp)
    }

    async deleteTransferItem(storeIndex, reason, index, p_id) {
        await this.sqlite.db.query(`DELETE FROM transfers where id = ${p_id}`);
        this.list[storeIndex].reasons[reason].splice(index, 1);
        if(this.temp.id == p_id){
            this.temp = false
            this.editing = { storeIndex: 0,index: 0,reason: ""};
        }
        for(let si in this.list) {
            for(let reason in this.list[si].reasons) {
                if(this.list[si].reasons[reason].length == 0) delete this.list[si].reasons[reason];
            }

            if(Object.values(this.list[si].reasons).length == 0) {
                this.list.splice(parseInt(si), 1);
            }
        }
    }

}

