import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private login: UrlTree;

  openPages = [
    'login',
    'buddy-registration',
    'forgot-password'
  ]

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.login = this.router.parseUrl('login');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    
    if (this.authService.isAuthenticated() && !this.openPages.includes(route.routeConfig.path)) {
        return true;
    }
    else if(this.authService.isAuthenticated() != null){
      if (this.openPages.includes(route.routeConfig.path)) return true;
      else return this.login;
    }
    else {
      return false;
    }
  }
}