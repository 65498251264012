export const createSchema: string = `

CREATE TABLE IF NOT EXISTS variables (
  variable_name TEXT PRIMARY KEY,
  variable_value TEXT NOT NULL
);

CREATE TABLE IF NOT EXISTS markdowns(
  job_id INT(10) NOT NULL,
  LongCode CHAR(20) NOT NULL, 
  ShortCode CHAR(20) NOT NULL, 
  Price1 CHAR(20) NOT NULL, 
  Price2 CHAR(20) NOT NULL, 
  scantime DATETIME,
  PRIMARY KEY ( job_id, LongCode )
);

CREATE TABLE IF NOT EXISTS transfers(
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  job_id INT(10) NOT NULL,
  from_store CHAR(20) NOT NULL, 
  to_store CHAR(20) NOT NULL, 
  transfer_reason CHAR(20) NOT NULL, 
  LongCode CHAR(20) NOT NULL, 
  ShortCode CHAR(20) NOT NULL, 
  quantity INT(10) DEFAULT '1' NOT NULL,
  scantime DATETIME NOT NULL
);

CREATE TABLE IF NOT EXISTS daily_products (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  LongCode CHAR(20) UNIQUE NOT NULL,
  ShortCode CHAR(20) UNIQUE NOT NULL,
  Description CHAR(100) NOT NULL
);

CREATE TABLE IF NOT EXISTS cycle_count (
  job_id INT(10) NOT NULL,
  ShortCode CHAR(20),
  LongCode CHAR(20) NOT NULL, 
  Description TEXT,
  quantity INT(10),
  scantime DATETIME,
  Status TEXT,
  PRIMARY KEY ( job_id, LongCode )
);

CREATE TABLE IF NOT EXISTS damages (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  job_id INT(10) NOT NULL,
  LongCode CHAR(20) NOT NULL,
  ShortCode CHAR(20) NOT NULL,
  ReasonCode CHAR(20) NOT NULL,
  quantity INT(10),
  scantime DATETIME
);

CREATE TABLE IF NOT EXISTS perpetual_inventory (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  job_id INT(10) NOT NULL,
  LongCode CHAR(20) NOT NULL,
  ShortCode CHAR(20) NOT NULL,
  new_quantity INT(10),
  scantime DATETIME
);

CREATE TABLE IF NOT EXISTS carton_scans (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  job_id INT(10) NOT NULL,
  CartonNo CHAR(20) NOT NULL,
  type VARCHAR(15) CHECK (type IN ('products', 'manual_products')), 
  reason CHAR(20),
  description TEXT,
  image TEXT,
  damaged INTEGER CHECK (damaged IN (0, 1)),
  scantime DATETIME,
  Status INT(5) NOT NULL
);

CREATE TABLE IF NOT EXISTS departments (
  department INTEGER PRIMARY KEY,
  description TEXT,
  status INTEGER
);

CREATE TABLE IF NOT EXISTS classes (
  class TEXT PRIMARY KEY,
  department_id INTEGER,
  description TEXT,
  status INTEGER,
  FOREIGN KEY (department_id) REFERENCES departments(department)
);
 
CREATE TABLE IF NOT EXISTS zones (
  zone TEXT,
  status INTEGER
);

CREATE TABLE IF NOT EXISTS master_stock (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  job_id INT(10) NOT NULL,
  Department TEXT NOT NULL,
  Class TEXT NOT NULL,
  ShortCode TEXT NOT NULL,
  LongCode TEXT NOT NULL,
  Description TEXT NOT NULL,
  CurrentStockUnits INTEGER,
  StockUnitsInTransit INTEGER,
  Markdown INTEGER,
  Date TEXT,
  quantity INTEGER,
  amended_quantity INTEGER,
  scantime TEXT,
  isFinalProduct INTEGER DEFAULT 0 CHECK (isFinalProduct IN (0, 1)),
  isActive INTEGER DEFAULT 0 CHECK (isActive IN (0, 1)),
  zone TEXT
);


CREATE INDEX IF NOT EXISTS daily_products_id_index ON daily_products (id);
CREATE INDEX IF NOT EXISTS daily_products_LongCode_index ON daily_products (LongCode);
CREATE INDEX IF NOT EXISTS daily_products_ShortCode_index ON daily_products (ShortCode);


CREATE INDEX IF NOT EXISTS transfers_id_index ON transfers (id);
CREATE INDEX IF NOT EXISTS transfers_job_id_index ON transfers (job_id);
CREATE INDEX IF NOT EXISTS transfers_multi_column_index ON transfers (job_id, from_store, to_store, transfer_reason, LongCode);
CREATE INDEX IF NOT EXISTS markdowns_index ON markdowns (job_id, LongCode);
CREATE INDEX IF NOT EXISTS cycle_count_idx_index ON cycle_count (job_id, LongCode);
CREATE INDEX IF NOT EXISTS damages_id_index ON damages (id);
CREATE INDEX IF NOT EXISTS damages_index ON damages (job_id, LongCode,ReasonCode);
CREATE INDEX IF NOT EXISTS perpetual_inventory_id_index ON perpetual_inventory (id);
CREATE INDEX IF NOT EXISTS master_stock_index ON master_stock (job_id, LongCode);
CREATE INDEX IF NOT EXISTS perpetual_inventory_index ON perpetual_inventory (job_id, LongCode);
CREATE INDEX IF NOT EXISTS carton_scans_id_index ON carton_scans (id);
CREATE INDEX IF NOT EXISTS carton_scans_index ON carton_scans (job_id, CartonNo);

CREATE INDEX IF NOT EXISTS idx_department ON departments(department);
CREATE INDEX IF NOT EXISTS idx_department_id ON classes(department_id);
CREATE INDEX IF NOT EXISTS idx_zone ON zones(zone);
`;

/*
  -- If Constraint
  -- CONSTRAINT unique_product_codes UNIQUE (LongCode, ShortCode)


CREATE TABLE IF NOT EXISTS users (
    id INTEGER PRIMARY KEY NOT NULL,
    email TEXT UNIQUE NOT NULL,
    name TEXT,
    company TEXT,
    size REAL,
    age INTEGER,
    last_modified INTEGER DEFAULT (strftime('%s', 'now'))
);
CREATE TABLE IF NOT EXISTS messages (
  id INTEGER PRIMARY KEY NOT NULL,
  userid INTEGER,
  title TEXT NOT NULL,
  body TEXT NOT NULL,
  last_modified INTEGER DEFAULT (strftime('%s', 'now')),
  FOREIGN KEY (userid) REFERENCES users(id) ON DELETE SET DEFAULT
);
CREATE TABLE IF NOT EXISTS images (
  id INTEGER PRIMARY KEY NOT NULL,
  name TEXT UNIQUE NOT NULL,
  type TEXT NOT NULL,
  size INTEGER,
  img BLOB,
  last_modified INTEGER DEFAULT (strftime('%s', 'now'))
);

CREATE INDEX IF NOT EXISTS users_index_name ON users (name);



CREATE TRIGGER IF NOT EXISTS users_trigger_last_modified
AFTER UPDATE ON users
FOR EACH ROW WHEN NEW.last_modified <= OLD.last_modified
BEGIN
    UPDATE users SET last_modified= (strftime('%s', 'now')) WHERE id=OLD.id;
END;
*/