import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DamageProduct } from 'src/app/models/survey.model';
import { DataService } from 'src/app/services/data.service';
import { Events } from 'src/app/services/events.service';
import { ScannerService } from 'src/app/services/scanner.service';
import { SQLiteService } from 'src/app/services/sqlite.service';

@Component({
  selector: 'damages',
  templateUrl: 'damages.html',
  styleUrls: ['./damages.scss']
})
export class DamagesComponent {
  objectKeys = Object.keys;

  @Input() damages;
  @Input() fieldSelected;
  @Input() damage_reason_codes;
  @Input() visibleSections;
  @Input() loaded;
  @Input() job_id;
  @Output() selectField : EventEmitter<any> = new EventEmitter()
  @Output() setSection : EventEmitter<any> = new EventEmitter()
  @Output() saveForm : EventEmitter<any> = new EventEmitter()

  pageLimit:number = 6; 
  page:number = 0;
  totalScanned:number = 0;
  list: any; //Need more time to create a model
  constructor(
    public data: DataService,
    private events: Events,
    private scanner: ScannerService,
    private sqlite: SQLiteService
  ) {
    this.events.subscribe('damages_added', () => this.load());
  }

  async ngAfterViewInit() {
    await this.load();
  }
  async load() {
      // await this.sqlite.db.query(`SELECT * FROM damages WHERE job_id = ${this.job_id} AND scantime IS NOT NULL ORDER BY scantime ASC LIMIT ${this.pageLimit} OFFSET ${this.page * this.pageLimit}`).then((res)=>{
      await this.sqlite.db.query(`SELECT * FROM damages WHERE job_id = ${this.job_id} AND scantime IS NOT NULL ORDER BY scantime`).then((res)=>{
      // Create an object to store products by ReasonCode
      this.list = this.scanner.damagesFormatting(res.values) 
      console.log(this.damages)
    })
  }

  editDamagedItem(reason, p) {
    let LongCode = p.LongCode
    this.damages.temp = p;
    this.damages.active = { reason, LongCode};
  }


  saveActiveDamage() {
    let result = this.damages.temp
    this.sqlite.db.query(`UPDATE damages SET quantity = ${result.quantity},LongCode = '${result.LongCode}', ReasonCode = '${result.ReasonCode}' WHERE id = ${result.id}`);
    this.load()
    this.damages.temp = {};
    this.damages.active = {};
  }
  
  
  setReasonCode(event: any) {
    this.damages.last_reason = event.target.value;
  }


  checkActive(reason, p) {
    if(!this.damages.active.reason || !this.damages.active.LongCode) return false;
    if(this.damages.active && this.list[reason]){
      return (p.LongCode == this.damages.active.LongCode && p.ReasonCode == this.damages.active.reason)
    }
    return false;
  }


  async removeItem(reason, i, id?) {
    await this.sqlite.db.query(`DELETE FROM damages where id = ${id}`);
    this.list[reason].splice(i, 1);
    this.damages.active = {};
    this.damages.temp = {};
  }
}
