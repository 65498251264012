import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;
  loader:any;

  constructor(public loadingController: LoadingController) { }

  async present(message='Please Wait...') {
    this.isLoading = true;
    this.loader = await this.loadingController.create({
      message: message
    })
    await this.loader.present();
    return this.loader
  }

  setContent(message) {
    this.loader.message = message;
  }

  async dismiss() {
    if(this.isLoading) {
      this.isLoading = false;
      return await this.loadingController.dismiss();
    }
    else this.isLoading = false;
  }
}