import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { Events } from 'src/app/services/events.service';
import { SQLiteService } from 'src/app/services/sqlite.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { PerpetualInventoryProduct } from 'src/app/models/survey.model';


@Component({
    selector: 'perpetual-inventory',
    templateUrl: 'perpetual-inventory.html',
    styleUrls: ['./perpetual-inventory.scss']
})
export class PerpetualInventoryComponent {

    @Input() perpetual_inventory;
    @Input() scanningData;
    @Input() fieldSelected;
    @Input() visibleSections;
    @Input() loaded;
    @Input() job_id;
    
    @Output() selectField : EventEmitter<any> = new EventEmitter();
    @Output() setSection : EventEmitter<any> = new EventEmitter();
    @Output() saveForm : EventEmitter<any> = new EventEmitter();

    @ViewChild(PaginationComponent) paginationComponent: PaginationComponent;

    products : PerpetualInventoryProduct[] = []
    component_name = "perpetual_inventory Where scantime IS NOT NULL AND"

    page:number = 0;
    pageLimit:number = 6;

    constructor(
        public data: DataService,
        private events: Events,
        private sqlite: SQLiteService,
        public alertCtrl: AlertController
    ) {
        this.events.subscribe('perpetual_inventory_added', (data) => {
            if(data == 1) this.paginationComponent.updateScanCount({status: true, operator : "++"});
            else this.paginationComponent.updateScanCount({status : false});
        });
    }

    async load(event) {
        if(event) this.page = event.page
        await this.sqlite.db.query(`SELECT * FROM perpetual_inventory WHERE job_id = ${this.job_id} AND scantime IS NOT NULL ORDER BY scantime ASC LIMIT ${this.pageLimit} OFFSET ${this.page * this.pageLimit}`).then((res)=>{
            this.products = res.values;
        })
    }

    async approvePerpetualInventory() {
        let alert = await this.alertCtrl.create({
        header: this.data.text["perpinv-confirm_approval"],
        subHeader: this.data.text["perpinv-confirm_approval_content"],
        buttons: [
            {
                text: this.data.text["svy-cancel"],
                role: 'cancel'
            },
            {
                text: this.data.text["svy-approve"],
                handler: ()=>{ 
                    this.perpetual_inventory.approved = true;
                    this.saveForm.emit();
                }
            }
        ]
        });
        alert.present();
    }

    async deleteProduct(id) {
        await this.sqlite.db.query(`DELETE FROM perpetual_inventory where id = ${id}`);
        this.paginationComponent.updateScanCount({status: true, operator : "--"});
    }

}