import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { DataService } from './data.service';
import { StorageService } from './storage.service';

import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class BreaksService {

    breakInterval = null;
    breakStart: number = 0;
    breakAlert:any;


    constructor(
        private alertCtrl: AlertController,
        private storage: StorageService,
        private data: DataService,
    ) { }


    async updateTime() {
        let data:any = await this.data.get('user/get_time_worked')
        this.data.time_worked = data.time_worked;
        this.data.week_time_worked = data.week_time_worked;

        if (this.data.week_time_worked.backgroundcolor == "#4fe83a"){
            this.data.week_time_worked.backgroundcolor = "rgb(61, 180, 35)";
        }
        if (this.data.time_worked.backgroundcolor == "#4fe83a") {
            this.data.time_worked.backgroundcolor = "rgb(61, 180, 35)";
        }

        let totalMins = data.total_mins;

        //get today breaks
        //punch in if open break
        let today = DateTime.local().toFormat('yyyy-MM-dd');
        let response:any = await this.data.post('breaks/list', { start_date: today, end_date: today })
        if(response) {
            let totalBreakSeconds = 0;
            if (response.breaks && response.breaks.length > 0) {
                let lastBreak = response.breaks[response.breaks.length - 1];
                if (!lastBreak.punch_in && this.breakInterval == null) {
                    let d = DateTime.fromSQL(lastBreak.punch_out_gmt, { zone: "utc" }).toLocal();
                    console.error("TAKING BREAK")
                    this.takingBreak(d);
                }          
            }
            if (response.totals && response.totals.All) {
                totalBreakSeconds = response.totals.All;
            }
            let stateBreakHrs = response.state_break_hrs ? response.state_break_hrs : 5;

            //if worked more than state required hours, they need at least 30 min break          
            if (totalMins >= stateBreakHrs*60 && totalBreakSeconds < 30*60) { 
                this.takeBreakAlert(
                    this.data.text['tabs-brk_alrt_1'], 
                    this.data.text['tabs-brk_alrt_3']+' '+stateBreakHrs+' '+this.data.text['tabs-brk_alrt_2']+' '+this.data.text['tabs-brk_sug']
                );
            }
        }
    }


    async takeBreakAlert(title: string = this.data.text['tabs-tk_brk'], subtitle: string = "") {
      let alert = await this.alertCtrl.create({
        header: title,
        subHeader: subtitle,
        inputs: [
          {
            type: 'radio',
            label: this.data.text['tabs-brk'],
            value: 'Break',
            checked: true
          },
          {
            type: 'radio',
            label: this.data.text['tabs-lunch'],
            value: 'Lunch'
          }
        ],
        buttons: [
          {
            text: this.data.text['tabs-lat'],
            role: 'cancel'
          },
          {
            text: this.data.text['tabs-strt'],
            handler: (punch_type) => {
                this.startBreak(punch_type)
            }
          }
        ]
      });
      alert.present();
    }


    async startBreak(punch_type:string) {
        //type = Break, Lunch
        let data:any = await this.data.post('breaks/punch_out', { punch_type })

        if (data.result == "failure") {
            //already on a break
            if(data.punch_out_gmt) {
                let punch_out = DateTime.fromSQL(data.punch_out_gmt, { zone: "utc" }).toLocal();
                this.data.toast(data.message);
                this.takingBreak(punch_out);
            }
            else {
                this.data.alert(data.message);
            }
        } 
        else {
            this.data.user.current_break = {
                punch_out_gmt: data.punch_out_gmt
            }
            this.storage.set('user', this.data.user);
            this.takingBreak();
        }
    }


    async endBreak() {
        let res:any = await this.data.get('breaks/punch_in')
        console.warn("breaks punch in result", res)

        if(this.data.user.breaks == undefined) this.data.user.breaks = [];

        //add this break to the user's list of breaks. Breaks list gets cleared when new route is started
        if(res.punch_out && res.punch_in) {
            this.data.user.breaks.push({
                punch_out: res.punch_out,
                punch_in: res.punch_in
            })
        }

        this.data.user.current_break = false;
        this.storage.set('user', this.data.user);
        
        clearInterval(this.breakInterval);
        this.breakStart = 0;

        if(this.breakAlert != undefined) this.breakAlert.dismiss();
    }

  
    async takingBreak(start?) {
        if(!start) start = DateTime.local();
        
        this.breakAlert = await this.alertCtrl.create({
            header: this.data.text['tabs-on_brk'],
            message: "0h 0m 0s",
            backdropDismiss: false,
            buttons: [
                {
                    text: this.data.text['tabs-bck_wrk'],
                    handler: () => {
                        this.endBreak()
                    },
                }
            ]
        });

        this.breakAlert.present();
        this.breakStart = start;
    

        this.breakInterval = setInterval(() => {
            let current_time = DateTime.local();
            let diff = current_time.diff(this.breakStart, ["hours", "minutes", "seconds"]).toObject();
            
            this.breakAlert.message = `${Math.abs(Math.floor(diff.hours))}h ${Math.abs(Math.floor(diff.minutes))}m ${Math.abs(Math.floor(diff.seconds))}s`;
        }, 1000);
    }


}
