import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'buddy-registration',
    loadChildren: () => import('./pages/buddy-registration/buddy-registration.module').then( m => m.BuddyRegistrationPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  
  {path: 'message', redirectTo: 'message/', pathMatch: 'full'},
  {
    path: 'message/:recipient_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/message/message.module').then( m => m.MessagePageModule)
  },
  {
    path: 'timesheets',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/timesheets/timesheets.module').then( m => m.TimesheetsPageModule)
  },
  {
    path: 'timesheet/:date',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/timesheet/timesheet.module').then( m => m.TimesheetPageModule)
  },
  {
    path: 'days-off',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/days-off/days-off.module').then( m => m.DaysOffPageModule)
  },
  {
    path: 'day-off',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/day-off/day-off.module').then( m => m.DayOffPageModule)
  },
  {
    path: 'availability',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/availability/availability.module').then( m => m.AvailabilityPageModule)
  },
  {
    path: 'paid-time-off',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/paid-time-off/paid-time-off.module').then( m => m.PaidTimeOffPageModule)
  },
  {path: 'paid-time-off-details', redirectTo: 'paid-time-off-details/', pathMatch: 'full'},
  {
    path: 'paid-time-off-details/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/paid-time-off-details/paid-time-off-details.module').then( m => m.PaidTimeOffDetailsPageModule)
  },
  {
    path: 'failed-jobs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/failed-jobs/failed-jobs.module').then( m => m.FailedJobsPageModule)
  },
  {
    path: 'survey-history',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/survey-history/survey-history.module').then( m => m.SurveyHistoryPageModule)
  },
  {
    path: 'jobs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/jobs/jobs.module').then( m => m.JobsPageModule)
  },
  {path: 'job', redirectTo: 'job/', pathMatch: 'full'},
  {
    path: 'job/:job_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/job/job.module').then( m => m.JobPageModule)
  },
  {
    path: 'survey',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/survey/survey.module').then( m => m.SurveyPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
