import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
 
  constructor(private sanitized: DomSanitizer) {}

  transform(value) {
    if(value) {
      let tmpArr = value.split(' ');
      for(let j=0;j<tmpArr.length;j++) {
        if(tmpArr[j].toLowerCase().startsWith('https://') || tmpArr[j].toLowerCase().startsWith('http://')) {
          tmpArr[j] = "<a href='"+tmpArr[j]+"'>"+tmpArr[j]+"</a>";
        }
      }
      value = tmpArr.join(' ');
      return this.sanitized.bypassSecurityTrustHtml(value);
    } else {
      return null;
    }
  }
}