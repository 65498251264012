import { HttpClient } from '@angular/common/http';
import { ActionSheetController, AlertController, Platform, PopoverController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

import { throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';

import { DataService } from 'src/app/services/data.service';
import { Events } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
  selector: 'StopEntry',
  templateUrl: './stop.component.html',
  styleUrls: ['./stop.component.scss'],
})
export class StopComponent implements OnInit {

  @Input() stop;
  @Input() savedSurvey:boolean = false;

  openSurveyAttempt: number = 1;

  constructor(
    public events: Events,
    public data: DataService,
    private storage: StorageService,
    private loader: LoadingService,
    public platform: Platform,
    public popoverCtrl: PopoverController,
    public actionSheetCtrl: ActionSheetController,

    private locationAccuracy: LocationAccuracy,
    private alertCtrl: AlertController,
    private perms: AndroidPermissions,
    private geolocation: Geolocation,
    private network: Network,
    private router: Router,
    private http: HttpClient,
    ) { }

  ngOnInit() {
    if(!this.stop.location) this.stop.location = this.stop.store;
    console.log("loaded stop", this.stop)
  }

  

  getTime(time) {
    if (time <= 59) return time + 'm';
    else {
      let mins = time % 60;
      let hours = time - mins;
      return hours / 60 + 'h ' + mins + 'm';
    }
  }


  checkTime() {
    return new Promise(async (res, rej) => {
      res(true); //bypassing this functionality for now - breaks are not supposed to be fully implemented yet

      let timeWorked : any = {};
      try {
        timeWorked = await this.data.get('user/get_time_worked?break_required=1');
      } 
      catch (error) {
        console.log(error);
      }
      
      if (!timeWorked.time_worked) {
        rej(false);
        return;
      }
      console.log('time worked', timeWorked);
      this.events.publish('updateTime', timeWorked.time_worked);
      if (timeWorked.time_worked.message) {
        let alert = await this.alertCtrl.create({
          header: this.data.text['fgpass-err'],
          message: timeWorked.time_worked.message,
          buttons: [
            {
              text: this.data.text['jo-start_rt_rr_c'],
              handler: () => rej(false)
            },
            {
              text: this.data.text['route-ok'],
              handler: () => {
                this.data.get('user/acknowledge?key=' + timeWorked.time_worked.key).then((data) => res(true));
              }
            }
          ]
        })
        alert.present();

      } else res(true);
    });
  }


  openMaps(location) {
    let url = 'https://www.google.com/maps/search/?api=1&query='+encodeURIComponent(`${location.address1} ${location.town} ${location.country} ${location.postcode}`);

    if (this.platform.is('ios')) {
      url = "http://maps.apple.com/maps?q="+encodeURIComponent(`${location.address1}, ${location.town}, ${location.country} ${location.postcode}`);
    }
    else if (this.platform.is('android')) {
      url = `geo:?q=${location.address1}+${location.town}+${location.country}+${location.postcode}`;
    }

    (<any>window).cordova.InAppBrowser.open(url, "_system",'location=no,hidden=yes')
  }


  async openSurvey(survey) {
    console.log('starting survey', survey);
    if (survey.completed || survey.queued) return;
  
    //if this is the saved survey
    if (this.savedSurvey) {
      this.storage.set('in_survey', DateTime.local().toFormat("y-MM-dd"))
      this.router.navigate(["/survey"]);
      return;
    }

    
    await this.loader.present(this.data.text['jo-start_rt_tsk_lmsg']);
    this.checkTime().then(async(val) => {
      //load the new survey data

      //if the user is not currently connected to a network, display a connection error
      if (this.platform.is('capacitor') && this.network.type == 'none') {
        this.data.alert(this.data.text['route-sur_err_lg'], this.data.text['route-conn_error'], this.data.text['jobc-dis'])
        this.loader.dismiss();
        return;
      }

      //otherwise continue to load the survey.
      //if on a mobile device, get location natively.
      let errorMessage:any = false;
      if (this.platform.is('capacitor')) {
        this.loader.setContent(this.data.text['jo-check_cam_perm']);
        let canRequestCamera = await this.data.checkPerms(this.perms.PERMISSION.CAMERA)

        this.loader.setContent(this.data.text['jo-check_storage_perm']);
        let canRequestStorageWrite = await this.data.checkPerms(this.perms.PERMISSION.WRITE_EXTERNAL_STORAGE)

        this.loader.setContent(this.data.text['jo-check_loc_perm']);
        let canRequestLocation = await this.locationAccuracy.canRequest();
        let canRequestHighAccuracy = await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);

        //if unable to request location, display a request location error
        if((!canRequestLocation || !canRequestHighAccuracy) && this.platform.is('android') ) errorMessage = this.data.text['route-sur_err_location_req']

        //if unable to request camera access, display a camera error
        else if(!canRequestCamera) errorMessage = this.data.text['route-sur_err_camera']

        //if unable to access external storage with write permissions.
        else if(!canRequestStorageWrite) errorMessage = this.data.text['route-sur_err_storage'];
      }


      this.loader.setContent(this.data.text['jo-rqst_location']);
      let geo:any = await this.geolocation.getCurrentPosition({ enableHighAccuracy: true, timeout: 20000 })

      //if unable to load geo location, display location error
      if(!geo.coords) errorMessage = this.data.text['route-sur_err_location']
      


      if(errorMessage !== false) {
        this.loader.dismiss();
        this.data.alert(errorMessage, this.data.text['route-sur_err'], this.data.text['jobc-dis'])
        return;
      }

    
      this.loader.setContent(this.data.text['jo-loading_surv_data'])
  
      let httpOptions = await this.data.setToken();
      let language = await this.storage.get('lang_id');
  
      return this.http.get(`${this.data.base_url}form/retrieve/${survey.store.id}?coordinates=${geo.coords.latitude},${geo.coords.longitude}&language=${language}`, httpOptions)
        .pipe(
          timeout(10000 * this.openSurveyAttempt), 
          catchError((err:any) => {
            if(this.openSurveyAttempt <= 3) this.openSurveyAttempt++
            return throwError("Timeout has occurred");
          })
        )
        .toPromise().then(async(data:any)=>{
          console.log('Retrieved form -> ', data)
          this.loader.dismiss();
  
          if (data.error) {
            this.data.alert(data.error, this.data.text['route-err'], this.data.text['jobc-dis'])
          }
          else {
            let surveyData = {
              job: data.job,
              store: data.store,
              scanner_modules: data.scanner_modules,
              coords: geo.coords
            }
            
            this.storage.set('in_survey', DateTime.local().toFormat("y-MM-dd"))
            await this.storage.set('activeSurveyData', surveyData);
            await this.router.navigate(["/survey"]);
          }
        }).catch((err)=>{
          this.loader.dismiss();
          this.data.alert(this.data.text['route-request_timed_out'], this.data.text['route-err'], this.data.text['jobc-dis'])
        });
    }).catch(async(e) => {
      console.log('checkTime fail', e);
      this.loader.dismiss();
      this.data.alert(this.data.text['route-sur_err_time'], this.data.text['route-sur_err'], this.data.text['jobc-dis']);
    })
  }

}
