import { AlertController } from '@ionic/angular';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { DateTime } from 'luxon';
import { DataService } from 'src/app/services/data.service';
import { Events } from 'src/app/services/events.service';
import { SQLiteService } from 'src/app/services/sqlite.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { CycleCountProduct } from 'src/app/models/survey.model';

@Component({
  selector: 'cycle-count',
  templateUrl: 'cycle-count.html',
  styleUrls: ['./cycle-count.scss']
})
export class CycleCountComponent {
  //Done
  @Input() cycle_count;
  @Input() fieldSelected;
  @Input() job_id;
  @Input() visibleSections;
  @Input() loaded;
  @Output() selectField : EventEmitter<any> = new EventEmitter()
  @Output() setSection : EventEmitter<any> = new EventEmitter()
  @Output() saveForm : EventEmitter<any> = new EventEmitter()

  @ViewChild(PaginationComponent) paginationComponent: PaginationComponent; 
  
  cycle_count_list: CycleCountProduct[] = [];

  component_name = "cycle_count Where scantime IS NOT NULL AND"

  viewManual:boolean = false;
  customCode:string;
  page: number = 0;
  pageLimit:number = 6;

  constructor(
    public data: DataService,
    private alertCtrl: AlertController,
    private events: Events,
    private sqlite: SQLiteService,
  ) {
    this.events.subscribe('cyclecount_added', (data) => {
      if(!data) this.paginationComponent.updateScanCount({status: true, operator : "++"});
      else this.paginationComponent.updateScanCount({status : false});
    });
  }

  async load(event) {
    if(event) this.page = event.page
    await this.sqlite.db.query(`SELECT * FROM cycle_count WHERE job_id = ${this.job_id} AND scantime IS NOT NULL ORDER BY scantime ASC LIMIT ${this.pageLimit} OFFSET ${this.page * this.pageLimit}`).then((res)=>{
      this.cycle_count_list = res.values;
    })
  }

  async approve() {
    let alert = await this.alertCtrl.create({
      header: this.data.text["cyc-approval_title"],
      subHeader: this.data.text["cyc-approval_subheader"],
      buttons: [
        {
          text: this.data.text["cyc-cancel"],
          role: 'cancel'
        },
        {
          text: this.data.text["cyc-approve"],
          handler: ()=>{
            this.cycle_count.approved = true
            this.saveForm.emit();
          }
        }
      ]
    });
    alert.present();
  }


  addCustom() {
    this.sqlite.db.query(`INSERT INTO cycle_count (job_id, LongCode, Description, quantity, scantime) VALUES (${this.job_id},'${this.customCode}','${this.data.text["cyc-custom"]}',1,'${DateTime.local().toFormat("y-MM-dd HH:mm:ss")}')`);
    this.paginationComponent.updateScanCount({status: true, operator : "++"});
    this.viewManual = false;
    this.customCode = '';
  }

  startManual() {
    this.viewManual = true;
    this.customCode = '';
  }

  cancel() {
    this.viewManual = false;
    this.customCode = '';
  }

}
