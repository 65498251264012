import { Injectable } from '@angular/core';

import { CapacitorSQLite, SQLiteDBConnection, SQLiteConnection, capSQLiteSet,
         capSQLiteChanges, capSQLiteValues, capEchoResult, capSQLiteResult,
         capNCDatabasePathResult } from '@capacitor-community/sqlite';
import { createSchema } from 'src/assets/sqlite_config';

@Injectable({
  providedIn: 'root'
})

export class SQLiteService {
    sqlite: SQLiteConnection;
    db: SQLiteDBConnection;

    constructor() {
    }
    /**
     * Plugin Initialization
     */
    async initializePlugin(): Promise<boolean> {
        const startTime = performance.now();
        this.sqlite = new SQLiteConnection(CapacitorSQLite);
        
        // initialize the connection
        this.db = await this.createConnection(
          'clarity-eu',
          false,
          'no-encryption',
          1,
        );

        // open db
        await this.db.open();

        // create tables in db
        let ret: any = await this.db.execute(createSchema);
        console.log('$$$ ret.changes.changes in db ' + ret.changes.changes);
        if (ret.changes.changes < 0) {
          return Promise.reject(new Error('Execute createSchema failed'));
        }
        const endTime = performance.now();
        const timeTaken = endTime - startTime;
        console.log(`Connection Built in ${timeTaken/1000} seconds`);
        return true;
    }


    /**
     * Create a connection to a database
     * @param database
     * @param encrypted
     * @param mode
     * @param version
     */
    async createConnection(database: string, encrypted: boolean, mode: string, version: number): Promise<SQLiteDBConnection> {
        this.ensureConnectionIsOpen();
        const db: SQLiteDBConnection = await this.sqlite.createConnection(database, encrypted, mode, version, false);
        if ( db == null ) {
            throw new Error(`no db returned is null`);
        }
        return db;
    }

    async clearScannerDatabases() {
        try {
            await this.db.beginTransaction(); // Start a transaction
            await this.db.query(`DELETE FROM markdowns`);
            await this.db.query(`DELETE FROM transfers`);
            await this.db.query(`DELETE FROM cycle_count`);
            await this.db.query(`DELETE FROM damages`);
            await this.db.query(`DELETE FROM perpetual_inventory`);
            await this.db.query(`DELETE FROM carton_scans`);
            await this.db.query(`DELETE FROM master_stock`);
            
            await this.db.query(`
                DELETE FROM SQLITE_SEQUENCE 
                WHERE name='transfers' OR name='carton_scans' OR name='master_stock'
            `);
            await this.db.query(`DELETE FROM variables WHERE variable_name='latest_stock_date'`);
            await this.db.commitTransaction();
            
          } catch (error) {
            // Rollback the transaction in case of an error
            await this.db.rollbackTransaction();
            console.error('Error inserting data:', error);
          }
    }
    
    
    /**
     * Close a connection to a database
     * @param database
     */
    async closeConnection(database: string): Promise<void> {
        this.ensureConnectionIsOpen();
        return this.sqlite.closeConnection(database, false);
    }

    /**
     * Retrieve an existing connection to a database
     * @param database
     */
    async retrieveConnection(database: string): Promise<SQLiteDBConnection> {
        this.ensureConnectionIsOpen();
        return this.sqlite.retrieveConnection(database, false);
    }

    /**
     * Retrieve all existing connections
     */
    async retrieveAllConnections(): Promise<Map<string, SQLiteDBConnection>> {
        this.ensureConnectionIsOpen();
        return this.sqlite.retrieveAllConnections();
    }

    /**
     * Close all existing connections
     */
    async closeAllConnections(): Promise<void> {
        this.ensureConnectionIsOpen();
        return this.sqlite.closeAllConnections();
    }

    /**
     * Check if connection exists
     * @param database
     */
    async isConnection(database: string): Promise<capSQLiteResult> {
        this.ensureConnectionIsOpen();
        return this.sqlite.isConnection(database, false);
    }

    /**
     * Check Connections Consistency
     * @returns
     */
    async checkConnectionsConsistency(): Promise<capSQLiteResult> {
        this.ensureConnectionIsOpen();
        return this.sqlite.checkConnectionsConsistency();
    }

    /**
     * Check if database exists
     * @param database
     */
    async isDatabase(database: string): Promise<capSQLiteResult> {
        this.ensureConnectionIsOpen();
        return this.sqlite.isDatabase(database);
    }

    /**
     * Get the list of databases
     */
    async getDatabaseList(): Promise<capSQLiteValues> {
        this.ensureConnectionIsOpen();
        return this.sqlite.getDatabaseList();
    }

    /**
     * Import from a Json Object
     * @param jsonstring
     */
    async importFromJson(jsonstring: string): Promise<capSQLiteChanges> {
        this.ensureConnectionIsOpen();
        return this.sqlite.importFromJson(jsonstring);
    }

    /**
     * Is Json Object Valid
     * @param jsonString Check the validity of a given Json Object
     */

    async isJsonValid(jsonString: string): Promise<capSQLiteResult> {
        this.ensureConnectionIsOpen();
        return this.sqlite.isJsonValid(jsonString);
    }

    private ensureConnectionIsOpen() {
        if ( this.sqlite == null ) {
            throw new Error(`no connection open`);
        }
    }
}