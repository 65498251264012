import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'DocumentList',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentList implements OnInit {

  @Input() categories;
  @Input() localFiles;

  downloadingMap: any = {};

  constructor(
    private file: File,
    private transfer: FileTransfer,
    private platform: Platform,
    private fileOpener: FileOpener,
    public data: DataService
  ) { }

  ngOnInit(): void {
    
  }

  selectDocument(doc) {
    let url = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(doc.link);
    if (doc.format == 'pdf') {
      url = doc.link;
    }
    if (doc.format == 'doc' || doc.format == 'docx') {
      url = 'https://docs.google.com/viewer?url=' + encodeURIComponent(doc.link);
    }
    if(this.platform.is('capacitor')) (<any>window).cordova.InAppBrowser.open(url, "_system",'location=no,hidden=yes')
    else window.open(url)
  }

  download(doc) {
    if (doc.format != "pdf") return;
    if (this.platform.is('capacitor')) {
      let filename = doc.link.substring(doc.link.lastIndexOf('/') + 1);

      const fileTransfer: FileTransferObject = this.transfer.create();
      // let progress = 0;
      // fileTransfer.onProgress((progressEvent) => {
      //   var perc = Math.floor(progressEvent.loaded / progressEvent.total * 100);
      //   if(progress != perc) {
      //     progress = perc;
      //     console.log('download progress - '+perc)
      //     // this.onDownloadProgress.emit(perc);
      //   }
      // });

      this.downloadingMap[doc.document_title] = true;
      fileTransfer.download(doc.link, this.file.dataDirectory + 'claires_documents/' + filename).then(async (entry) => {
        this.downloadingMap[doc.document_title] = false;
        console.log("Download complete: " + entry.toURL());
        this.localFiles.push(filename);

        var _self = this;
        entry.getMetadata(function (metadata) {
          //delete if empty file
          if (metadata.size == 0) {
            _self.data.toast(_self.data.text['documents-file_not_found']);
            _self.removeFromLocalFiles(filename);
            _self.deleteLocal(doc);
          }
          else {
            console.log("Downloaded file size "+metadata.size, metadata)
          }
        });

      }, (error) => {
        this.downloadingMap[doc.document_title] = false;
        console.log("Download error", error);
      });
    }
    else {
      this.data.toast(this.data.text['documents-download_not_necessary_for_web']);
      this.selectDocument(doc);
    }
  }


  openLocal(doc) {
    if (doc.format != "pdf") return;
    if (this.platform.is('capacitor')) {
      let filename = doc.link.substring(doc.link.lastIndexOf('/') + 1)
      this.fileOpener.open(this.file.dataDirectory + "claires_documents/" + filename, 'application/pdf')
        .then(() => console.log('File opened'))
        .catch(e => console.log('Error opening file', e));
    }
    else {
      console.log("Open local not needed for web");
      this.selectDocument(doc);
    }
  }


  deleteLocal(doc) {
    if (doc.format != "pdf") return;
    let filename = doc.link.substring(doc.link.lastIndexOf('/') + 1)
    if (!this.platform.is('capacitor')) {
      this.removeFromLocalFiles(filename);
      return
    }

    this.file.removeFile(this.file.dataDirectory+"claires_documents", filename)
      .then(() => {
        this.removeFromLocalFiles(filename);
      })
      .catch(e => {
        console.log('Error deleting file', e);
        if (e.code === 1) {
          this.removeFromLocalFiles(filename);
        }
      });
  }


  removeFromLocalFiles(filename) {
    let index = this.localFiles.indexOf(filename);
    if (index > -1) {
      this.localFiles.splice(index, 1);
    }
  }

  isSavedLocally(doc) {
    let filename = doc.link.substring(doc.link.lastIndexOf('/') + 1)
    return this.localFiles.indexOf(filename) !== -1;
  }

  getCategoryName(title: string) {
    if (title == 'Hyvee') return 'Hy-Vee';
    return title;
  }

}
