import { AlertController, ToastController, Platform, AlertOptions } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { StorageService } from './storage.service';
import { LoadingService } from './loading.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {


  //server_url = "https://castretail.swdlab.com/";
  //server_url = "https://clairesdev.swdlab.com/";
  //server_url = "https://clairity.app/claireseu/";

//   server_url = "https://appifi.co/claireseu/"; //latest
  server_url = "https://euclarity.claires.app/claireseu/";

  // base_url = 'http://castretail.swdlab.com/_endpoints/claires_app/';
  //base_url = this.server_url +'_endpoints/claires_sf/';
  base_url = this.server_url + 'ep/';

  marker_url = 'https://appifi.co/handlers/claireseu/css/store-icon3.png';
  

  public text:any = {}

  app_version;

  httpOptions = {}
  user:any = {
    buddy: false,
    breaks: [],
  }

  time_worked;
  week_time_worked;

  tabMenuJobCount = 0;

  in_survey: boolean = false;
  is_synced: boolean = false;

  devMode = false;
  devModeCounter = 0;
  devModeTimer;


  constructor(
    public http: HttpClient,
    public perms: AndroidPermissions,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private storage: StorageService,
    private loader: LoadingService,
    private platform: Platform,
  ) {
  }

  async ngOnInit() {
    this.setToken();
  }


  async handleQueuedForms(source="") {
    if(!this.text) this.text = await this.storage.get('language')

    return new Promise(async (res, rej) => {
      console.error('Handle queued forms from '+source)
      let queuedForms:any = await this.storage.get('queuedForms');
      let route:any = await this.storage.get('route');

      if(queuedForms && queuedForms.length > 0) {
        let successCount = 0;
        let fIndex = 0;
        //let length = queuedForms.length;
        let failedSurveys = [];

        for(let form of queuedForms) {
          let job_id = form.formData.job_id;
          let remove = false;

          let data:any = await this.post('form/post', form.formData).then((data)=>data);

          // submit inventory data
          if(data.result === 'success' && data.job_id) {
              // if(form.inventory.markdowns.show) await this.post('inventory/send/markdowns', {...form.inventory.markdowns, job_id})
              // if(form.inventory.carton_scans.show) await this.post('inventory/send/carton_scan', {...form.inventory.carton_scans, job_id})
              // if(form.inventory.transfers.show) await this.post('inventory/send/transfers', {...form.inventory.transfers, job_id})
              // if(form.inventory.cycle_count.show) await this.post('inventory/send/cycle_count', {...form.inventory.cycle_count, job_id})
              remove = true;
          }
          // if job is already completed
          else if(data.code && data.code == 801) remove = true;

          if(remove) {
            successCount += 1;
            if(route && route.routeList) {
              for(let i in route.routeList) {
                if(job_id == route.routeList[i].store.id) {
                  route.routeList[i].queued = false;
                  route.routeList[i].completed = true;
                }
              }
            }
          }
          else {
            failedSurveys.push(form);
          }

          fIndex++;

          if(fIndex === queuedForms.length) {
            this.storage.set('route', route);
            this.storage.set('queuedForms', failedSurveys);
            res({successCount, length: queuedForms.length});
          }
        }
      }
      else {
        rej({message: this.text['svyhist-no_queued']});
      }
    })
  }

  uptickDevMode() {
    this.devModeCounter++;
    if(!this.devModeTimer) {
      this.devModeTimer = setTimeout(()=>{
        this.devModeCounter = 0;
        clearTimeout(this.devModeTimer); 
        this.devModeTimer = null;
      }, 5000);
    }
    if(this.devModeCounter === 5) {
      this.devMode = !this.devMode;
      this.devModeCounter = 0;
      clearTimeout(this.devModeTimer); 
      this.devModeTimer = null;
    }
  }

  async reloadData() {
    await this.loader.present(this.text['menu-downloading_data']+"...");
    let lang_id = await this.storage.get('lang_id');
    let lang_keys = await this.post('user/language/',{'language_id': lang_id ? lang_id : 0}).then(({lang_keys})=>lang_keys)
    this.storage.set('language', lang_keys)
    this.text = lang_keys;

    this.loader.dismiss();
    this.toast(this.text['menu-data_downloaded']);
  }

  setLanguage($event) {
    return new Promise((res, rej) => {
      // gets the language respone and stores it locally
      let lang = $event || '0';
      this.post('user/language/',{'language_id': lang}).then((data:any)=>{
        this.storage.set('lang_id', lang);
        this.storage.set('language', data.lang_keys)
        this.text = data.lang_keys;
      });
    });
  }


  async checkPerms(perm:any) {
    if(perm === 'CAMERA') perm = this.perms.PERMISSION.CAMERA
    if(perm === 'WRITE_EXTERNAL_STORAGE') perm = this.perms.PERMISSION.WRITE_EXTERNAL_STORAGE
    return new Promise((resolve, reject) => {
      if(!this.platform.is('android')) resolve({granted:true});
      
      this.perms.checkPermission(perm).then((data:any) => {
        if(data.hasPermission) resolve({granted:true});
        else {
          this.perms.requestPermission(perm)
            .then(() => resolve({granted:true}))
            .catch(()=>reject(false))
        }
      })
      .catch(()=>reject(false))
    });
  }

  async setToken(token: any = false) {
    if(token) this.storage.set('token', token);
    else token = await this.storage.get('token').then((token:string) => token);
    this.httpOptions = {
      headers: new HttpHeaders ({
        'X-Auth-Token': token ? token : ''
      })
    }
    return this.httpOptions
  }

  post(endpoint, data){
    return new Promise(async (res, rej) => {
      await this.setToken();
      this.http.post(this.base_url+endpoint, data, this.httpOptions).toPromise().then((ret:any)=>{
        console.log(endpoint, ret);
        this.is_synced = true;
        res(ret);
      }).catch((e)=>{
        this.is_synced = false;
        console.warn('HTTP Post error: ', e)
        rej(e)
      });
    });
  }

  get(endpoint){
    return new Promise(async (res, rej) => {
      await this.setToken();
      this.http.get(this.base_url+endpoint, this.httpOptions).toPromise().then((ret:any)=>{
        console.log(endpoint, ret);
        this.is_synced = true;
        res(ret);
      }).catch((e)=>{
        this.is_synced = false;
        console.warn('HTTP Get error: ', e)
        rej(e)
      });
    });
  }
  

  public async alert(message: string, title: string = "", button: string = "", subheader: string = "") {
    if(button == "") button = this.text['menu-dismiss'] ? this.text['menu-dismiss'] : "OK";
    if(title == "") title = this.text['menu-alert-title'] ? this.text['menu-alert-title'] : "Alert";
    
    let alertOptions:AlertOptions = {
      cssClass: 'my-custom-class',
      header: title,
      message: message,
      buttons: [
        {
          text: button,
          role: 'cancel',
        }
      ]
    }

    if(subheader != "") alertOptions.subHeader = subheader;

    const alert = await this.alertCtrl.create(alertOptions);
    await alert.present();

    const data = await alert.onDidDismiss();
  }

  toast(message, duration=3000) {
    return new Promise(async(res, rej) => {

      const toast = await this.toastCtrl.create({
        message: message,
        duration: duration,
        position: 'top'
      });

      toast.onDidDismiss().then(()=>{
        res(true);
      });
    
      toast.present();
    })
  }



}
