import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any) {
    console.log('setStorage - '+key, value);
    if(typeof value === 'object') value = JSON.stringify(value);
    return this._storage?.set(key, value);
  }

  public async get(key: string){
    return new Promise((res, rej) => {
      this.storage.get(key).then((data)=>{
        try {
          let parsed = JSON.parse(data);
          if (parsed && typeof parsed === "object") {
              console.log('getStorage - '+key, parsed);
              return res(parsed);
          }
          else {
            console.log('getStorage - '+key, data);
            res(data);
          }
        }
        catch (e) { res(data) }
      });
    })
  }

  public async remove(key: string){
    console.log('removeStorage - '+key);
    return this.storage.remove(key);
  }

  public async clear(){
    return this.storage.clear();
  }


}