import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';

import { AuthService } from './core/auth/auth.service';
import { DataService } from './services/data.service';
import { StorageService } from './services/storage.service';

import { DateTime } from 'luxon';

import { filter } from 'rxjs/operators';
import { ScannerService } from './services/scanner.service';
import { BreaksService } from './services/breaks.service';
import { SQLiteService } from './services/sqlite.service';
import { Events } from './services/events.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  oldConnection;
  showMenu;
  currentPage;

  constructor(
    platform: Platform,
    zone: NgZone,
    public authService: AuthService,
    public data: DataService,
    private breaks: BreaksService,
    private router: Router,
    private scanner: ScannerService,
    private storage: StorageService,
    private _sqlite: SQLiteService,
    private events: Events,
  ) {
    platform.ready().then(async () => {
        if(platform.is('capacitor')) { 
            // if(!this._sqlite.isConnection("clarity-eu")){
              await this._sqlite.initializePlugin();
            // }

            if(window.screen.orientation && window.screen.orientation.unlock) {
                window.screen.orientation.lock('portrait');
            }
            
            StatusBar.setOverlaysWebView({ overlay: false });
            await StatusBar.setStyle({ style: Style.Dark });
            StatusBar.setBackgroundColor({color: "553d94"});

            App.getInfo().then((res)=>{
                this.data.app_version = res.version;
            })
        };

            
        App.addListener('appStateChange', async ({ isActive }) => {
            console.log('App state changed. Is active?', isActive);
            if(isActive) {
                await StatusBar.setStyle({ style: Style.Dark });
                StatusBar.setBackgroundColor({color: "553d94"});
            }
        });


      let saveLogin = await this.storage.get('saveLogin');
      if(saveLogin) this.authService.login();
      else this.router.navigate(['login']);

      let user:any = await this.storage.get('user')
      if(user) {
        if(!user.breaks) user.breaks = [];
        this.data.user = user;
        this.events.publish('user_authenticated', true);
      }

      console.warn('this user', this.data.user)

      this.storage.get("in_survey").then((date:any)=> {
        if(date !== false) {
          let today = DateTime.local().toFormat("yyyy-LL-dd");
          this.data.in_survey = date == today;
          console.log('comparing survey date to today', date, today)

          //clear saved survey data if the survey was not from today
          if(date != today) {
            this.storage.remove('savedForm')
            this.storage.remove('activeSurveyData')
          }
        }
        else this.data.in_survey = false;
      })
      

      this.storage.get('language').then((language)=>{
        this.data.text = language ? language : [];
      })
      //fetch daily scanner product list

      await this.scanner.fetchDailyProductList();
      await this.scanner.fetchInventoryData();
       
      Network.addListener('networkStatusChange', status => {
        if (status.connected && status.connectionType != this.oldConnection) {
          zone.run(()=>{
            this.oldConnection = status.connectionType;
            if(status.connectionType == "none") {
            //   this.data.is_synced = false;
              this.data.alert(this.data.text['tabs-chk_conn'], this.data.text['route-conn_error'], this.data.text['jobc-dis']);
              console.error("Network Disconnected")
            }
            else {
            //   this.data.is_synced = true;
              console.warn("Network "+status.connectionType+" Connected")
              this.data.toast(this.data.text['tabs-conn_to']+' ' + status.connectionType)
              data.handleQueuedForms('connected to network')
            }
          })
        }
      });

      this.data.is_synced = await Network.getStatus().then((res)=>res.connected);


      // let connectionInterval = setInterval(()=>{
      //   this.data.get("user/connection_check").then((res:any)=>{
      //       this.data.is_synced = res.success;
      //   })
      //   .catch((err)=>{
      //       this.data.is_synced = false;
      //   })
      // }, 10000);



      setTimeout(()=>{
        if (this.data.is_synced) {
          data.handleQueuedForms('has connection').then((res)=>console.log(res)).catch((err)=>console.error(err));
        }
        else {
          this.data.alert(this.data.text['tabs-chk_conn'], this.data.text['route-conn_error'], this.data.text['jobc-dis']);
        }
      }, 5000)
      
      this.authService.getAuthStateObserver().subscribe((state) => {
        if (state) {
          if(this.data.in_survey) this.router.navigate(['/survey']);
          else this.router.navigate(['/'])
        }
        else {
          console.error('no state!', state)
          this.router.navigate(['/login'])
        }
      });


      if(platform.is('capacitor')) { 
        setTimeout(()=>SplashScreen.hide(), 500);
      }

    });
  }


  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((e:any)=>{
      if(e.url) {
        this.currentPage = e.url; 
        this.showMenu = e.url !== '/login';
      }
    });
  }


  reloadData() {
    this.data.reloadData();
    this.scanner.fetchDailyProductList();
  }
}

