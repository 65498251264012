import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { Department, StockInventoryProduct, Zone } from 'src/app/models/survey.model';
import { DataService } from 'src/app/services/data.service';
import { Events } from 'src/app/services/events.service';
import { ScannerService } from 'src/app/services/scanner.service';
import { SQLiteService } from 'src/app/services/sqlite.service';

@Component({
  selector: 'stock-inventory',
  templateUrl: 'stock-inventory.html',
  styleUrls: ['./stock-inventory.scss']
})
export class StockInventoryComponent {

    @Input() stock_inventory;
    @Input() fieldSelected;
    @Input() visibleSections;
    @Input() loaded;
    @Input() job_id;
    @Output() selectField : EventEmitter<any> = new EventEmitter();
    @Output() setSection : EventEmitter<any> = new EventEmitter();
    @Output() saveForm : EventEmitter<any> = new EventEmitter();
    departments : Department[] = [];
    zones : Zone[] = [];
    scanned_products :StockInventoryProduct[] = []
    final_product_list : StockInventoryProduct[] = []
 
    constructor(
        public scanner: ScannerService, 
        public data: DataService,
        private events: Events,
        public alertCtrl: AlertController,
        public actionSheetCtrl: ActionSheetController,
        private sqlite: SQLiteService
    ) {
        this.events.subscribe('stock_inventory_added', () => {
            this.load();
        });
    }
    
    async ngAfterViewInit() {
        if(this.stock_inventory.departments.length == 0){
            await this.getData();
        }
        await this.load();
    }

    async getData() {
        let query = `SELECT
        departments.department AS Department,
        departments.description AS Description,
        departments.status AS Status,
        json_group_array(
          json_object(
            'Class', classes.class,
            'Description', classes.description,
            'Status', classes.status
          )
        ) AS class_list
      FROM
        departments
      LEFT JOIN
        classes ON departments.department = classes.department_id
      GROUP BY
        departments.department, departments.description, departments.status;
      `;
        let dept = await this.sqlite.db.query(query);

        this.stock_inventory.departments = dept.values;
        this.stock_inventory.departments.map((department)=>{
            department.class_list = JSON.parse(department.class_list);
        })

        let query1 = `SELECT zone AS Zone, status AS Status FROM zones;`;
        let zones = await this.sqlite.db.query(query1);
        this.stock_inventory.zones = zones.values;
    }

    async load() {
        if(this.stock_inventory.view == 'scan_products'){
            await this.sqlite.db.query(`SELECT * FROM master_stock WHERE job_id = ${this.job_id} AND isActive = 1 AND scantime IS NOT NULL`).then((res)=>{
                this.scanned_products = res.values;
            })
        }

        if(this.stock_inventory.view == 'approval'){
            await this.sqlite.db.query(`SELECT * FROM master_stock WHERE job_id = ${this.job_id} AND isActive = 1 AND scantime IS NOT NULL AND isFinalProduct=1`).then((res)=>{
                this.final_product_list = res.values;
            })
        }
    }

    /**
     * There currently is no validation in place for this function. 
     * Not sure if there is supposed to be any? 
     */
    confirmVersion() {
        this.stock_inventory.version_confirmed = true;
        this.stock_inventory.view = 'select_department';
        this.saveForm.emit();
    }



    /**
     * Function to handle adding departments to the count
     * @param event ionChange Event, contains an array of selected Department IDs
     */
    addDepartments(event) {

        // this.stock_inventory.selected_departments = event.target.value

        this.stock_inventory.departments.map((d)=>{
            if(event.target.value.includes(d.Department)) d.selected = true;
        })
        this.saveForm.emit();
    }

    /**
     * Function to handle adding classes to the count
     * @param department the selected department that the classes selected under
     * @param event ioncCHnage Event, contains an array of selected Class IDs
     */
    addClasses(department, event) {
        let class_list = event.target.value
        let selectedAll = false;
        
        for(let c of class_list) {
            if(c == "All") {
                department.class_list.map((c)=>c.selected = true);
                selectedAll = true;
            }
            else if(!selectedAll) {
                c.selected = true;
            }
        }
        this.saveForm.emit();
    }



    /**
     * Function to handle removing a department from the count
     * @param d the department to be removed
     */
    removeDepartment(d) {
        let matches = this.scanned_products.filter((prod)=>prod.Department == d.Department)

        if(matches.length > 0) this.data.alert(`${this.data.text["stockinv-department"]} ${d.Description} ${this.data.text["stockinv-cannot_be_rmvd"]}. ${matches.length} ${this.data.text["stockinv-count_scanned"]}`)
        else {
            for(let c of d.class_list) { c.selected = false; }
            d.selected = false;
        }
        this.saveForm.emit();
    }

    /**
     * Function to handle removing a class from the count
     * @param c the class to be removed
     */
    removeClass(c) {
        let matches = this.scanned_products.filter((prod)=>prod.Class == c.Class)

        if(matches.length > 0) this.data.alert(`${this.data.text["stockinv-class"]} ${c.Description} ${this.data.text["stockinv-cannot_be_rmvd"]}. ${matches.length} ${this.data.text["stockinv-count_scanned"]}`)
        else c.selected = false;
        this.saveForm.emit();
    }
 


    /**
     * Getter function to get a list of all selected Departments
     * @returns returns an array of departments that have been selected
     */
    getSelectedDepartments() {
        return this.stock_inventory.departments.filter((d)=>d.selected)
    }

    /**
     * Function to get a list of all selected classes
     * @param dept An optional parameter to specify what department to return a list of selected classes for
     * @returns list of selected Classes
     */
    getSelectedClasses(dept:any=0) {
        if(typeof dept == 'object') dept = dept.Department
        let departments = this.stock_inventory.departments.filter((d)=>dept > 0 ? d.Department == dept && d.selected : d.selected)
        let classes = [];
        departments.map((d)=>{
            let selected = d.class_list.filter((c)=>c.selected);
            classes = [...classes, ...selected]
        })
        return classes;
    }


    /**
     * Loop through all selected departments and classes, adding any products to the active_masterstock list
     */
    confirmClasses() {
        /**
         * stock_inventory.masterstock is an object, indexed by department and class IDs.
         * for example: 
         * stock_inventory.masterstock = {
         *   departmentID: {
         *     classID: [
         *       productInThisDeptAndClass,
         *       otherProductsInThisDeptAndClass
         *     ]
         *   }
         * }
         * 
         *  active_masterstock contains all products that are valid to scan. 
         * Reset it to an empty array before figuring out what products are valid
         * */ 
        this.stock_inventory.active_masterstock = [];
        //New
        const selectedDepartments = this.stock_inventory.departments.filter(d => d.selected == true);
        if(selectedDepartments.length > 0){
            selectedDepartments.forEach((dept)=>{
                const selectedClasses = dept.class_list.filter(c => c.selected == true);
                if(selectedClasses.length > 0){
                    selectedClasses.forEach(async (cl)=>{
                        let query = `UPDATE master_stock set isActive = 1 WHERE Class = '${cl.Class}'`;
                        await this.sqlite.db.query(query);
                    })
                }
            })
        }

        this.stock_inventory.view = 'ask_count_markdown_items';
        this.saveForm.emit();
    }



    /**
     * @returns count of how many products have been scanned for the currently active zone
     */
    getProductZoneCount() {
        return this.scanned_products.filter((prod)=>prod.zone == this.stock_inventory.activeZone).length
    }


    /**
     * Returns whether the passed product is the currently selected active adjustment
     * @param prod Scanned product
     * @returns 
     */
    checkActiveAdjustment(prod) {
        if(!this.stock_inventory.active_adjustment) return false;
        if(prod.LongCode !== this.stock_inventory.active_adjustment.LongCode) return false;
        if(prod.zone !== this.stock_inventory.active_adjustment.zone) return false;
        return true;
    }

    /**
     * Stores the user's selection and moves the user to the Zone section
     * @param val boolean value to show whether the user wishes to count markdown products or not
     */
    countMarkdowns(val) {
        this.stock_inventory.count_markdown = val;
        this.stock_inventory.view = "enter_zone";
        this.saveForm.emit();
    }



    /**
     * Validates the selected Zone, and moves the user to the Scan Products section
     */
    confirmZone() {
        if(!this.stock_inventory.activeZone || this.stock_inventory.activeZone == "") {
            this.data.alert(this.data.text["stockinv-zone_cannot_empty"])
            return;
        }

        delete this.stock_inventory.active_adjustment;
        delete this.stock_inventory.last_scanned;

        this.stock_inventory.view = "scan_products";
        this.saveForm.emit();
    }



    /**
     * 
     * @returns the difference between the current stock units (with product quantity subtracted from it), and the amended quantity
     */
    getDifference() {
        let aa = this.stock_inventory.active_adjustment;
        let val1 = (aa.CurrentStockUnits - aa.quantity)
        let val2 = aa.amended_quantity ?? 0
        let sign = "";
        if(val1 > val2) sign = '-';
        else if(val1 < val2) sign = '+';

        let diff = sign+(Math.abs(val1 - val2));
        return diff; 
    }
 


    /**
     * Opens the Options drawer from the bottom. Buttons are autopopulated based on the current page
     */
    async openOptions() {
        let buttons = [];

        //uncomment for dev purposes - consoles out the entire inventory.stock_inventory object
        // buttons.push({
        //     text: 'DEV - console log stock inventory',
        //     icon: 'alert',
        //     handler: () => {
        //         console.warn("Stock Inventory",  this.stock_inventory)
        //     }
        // })


        if(this.stock_inventory.view == "scan_products") {
            //this brings the user to the final table where they can view all the scanned products, and adjustments
            buttons.push({
                text: this.data.text["stockinv-approve_all_counts"],
                icon: 'checkmark',
                handler: () => {
                    let products = [];
                    for(let prod of this.scanned_products) {
                        this.sqlite.db.query(`UPDATE master_stock SET isFinalProduct = 1 where id = ${prod.id}`);
                        prod.isFinalProduct = true;
                        products.push(prod);
                    }

                    products.sort((a, b) => (b.CurrentStockUnits - b.quantity) - (a.CurrentStockUnits - a.quantity));

                    console.log('stock_inventory.scanned_products', this.scanned_products)
                console.log('Sorted products', products);
                    
                    this.final_product_list = products;
                    this.stock_inventory.view = 'approval';
                    this.saveForm.emit();
                }
            });


            //this allows the user to enter a new zone
            buttons.push({
                text: this.data.text["stockinv-new_zone"],
                icon: 'add',
                handler: () => this.stock_inventory.view = 'enter_zone'
            });
        }


        if(this.stock_inventory.view == "approval") {
            //this approves all scanned products - user can no longer scan additional products, or add departments/classes.
            buttons.push({
                text: this.data.text["stockinv-approve_count"],
                icon: 'checkmark',
                handler: async () => {
                    this.saveForm.emit();
                    let productCount = 0;
                    for(let prod of this.final_product_list) {
                        productCount += prod.quantity;
                        if(prod.amended_quantity) productCount += prod.amended_quantity;
                    }
                    
                    let alert = await this.alertCtrl.create({
                        header: this.data.text["stockinv-approve_all"]+" "+(this.final_product_list.length)+" "+this.data.text["stockinv-rows"],
                        subHeader: productCount+" "+this.data.text["stockinv-products_counted"],
                        message: this.data.text["stockinv-approval_confirmation"],
                        buttons: [{
                            text: this.data.text["stockinv-yes"],
                            handler: () => {
                                this.stock_inventory.view = 'fully_approved'
                                this.saveForm.emit();
                            }
                        },
                        {
                            text: this.data.text["stockinv-no"],
                            handler: () => {
                                this.stock_inventory.view = 'not_approved'
                                this.saveForm.emit();
                            }
                        }]
                    })
                    alert.present();
                }
            });

            //if there is an active adjustment, allow the user to amend it
            let adjustment = this.stock_inventory.active_adjustment;
            if(adjustment) {
                buttons.push({
                    text: this.data.text["stockinv-amend"],
                    icon: 'create',
                    handler: () => {
                        adjustment = this.final_product_list.filter((prod)=>prod.LongCode == adjustment.LongCode)[0]
                        adjustment.amended_quantity = 0;
                        this.stock_inventory.view = 'amend';
                    }
                });
            }
        }
        

        if(["scan_products", "approval"].includes(this.stock_inventory.view)) {
            //this allows the user to return and select additional departments/classes
            buttons.push({
                text: this.data.text["stockinv-select_dept_class"],
                icon: 'checkbox-outline',
                handler: () => {
                    this.stock_inventory.view = 'select_department'
                },
            })
        }


        if(this.stock_inventory.view == "amend") {
            let sia = this.stock_inventory.active_adjustment;
            buttons.push({
                text: this.data.text["stockinv-save_amendment"],
                icon: 'checkmark',
                handler: () => {
                    for(let [key, value] of Object.entries(this.final_product_list)) {
                        let prod:any = value;
                        if(prod.LongCode == sia.LongCode && prod.zone == sia.zone) {
                            // this.final_product_list[key] == this.stock_inventory.active_adjustment;
                            prod.amended_quantity = sia.amended_quantity;
                            this.sqlite.db.query(`UPDATE master_stock set amended_quantity = ${sia.amended_quantity} where id = ${prod.id}`)
                        }
                    }

                    this.saveForm.emit();
                    this.stock_inventory.view = 'approval';
                }
            });
        }



        if(this.stock_inventory.view == "not_approved") {
            buttons.push({
                text: this.data.text["stockinv-approval"],
                icon: 'checkmark',
                handler: () => {
                    let products = [];
                    for(let prod of this.scanned_products) {
                        products.push(prod);
                    }

                    let sorted = products.sort((a, b) => (b.CurrentStockUnits - b.quantity) - (a.CurrentStockUnits - a.quantity))
                    this.final_product_list = sorted;
                    this.stock_inventory.view = 'approval';
                    this.saveForm.emit();
                }
            });
        }

        //create the action sheet drawer from the bottom
        const actionSheet = await this.actionSheetCtrl.create({
            header: this.data.text["stockinv-options_menu"],
            buttons: [...buttons,
                {
                    text: this.data.text["svy-cancel"],
                    icon: 'close',
                    role: 'cancel'
                }
            ]
        });
        actionSheet.present();
    }

}