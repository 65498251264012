import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DocumentList } from './document-list/document-list.component';
import { StopComponent } from './stop/stop.component';
import { CartonScansComponent } from './carton-scans/carton-scans';
import { CycleCountComponent } from './cycle-count/cycle-count';
import { DamagesComponent } from './damages/damages';
import { MarkdownsComponent } from './markdowns/markdowns';
import { PerpetualInventoryComponent } from './perpetual-inventory/perpetual-inventory';
import { StockInventoryComponent } from './stock-inventory/stock-inventory';
import { TransfersComponent } from './transfers/transfers';
import { PaginationComponent } from './pagination/pagination.component';


@NgModule({
	declarations: [
    DocumentList,
    StopComponent,
		CycleCountComponent,
		TransfersComponent,
		MarkdownsComponent,
		CartonScansComponent,
		PerpetualInventoryComponent,
		StockInventoryComponent,
		DamagesComponent,
		PaginationComponent
    ],
	imports: [
    FormsModule,
    CommonModule,
    IonicModule
    ], 
	exports: [
    DocumentList,
    StopComponent,
		CycleCountComponent,
		TransfersComponent,
		MarkdownsComponent,
		CartonScansComponent,
		PerpetualInventoryComponent,
		StockInventoryComponent,
		DamagesComponent,
		PaginationComponent
    ],
	schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ] 
})
export class ComponentsModule {}
