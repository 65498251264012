import { AlertController, MenuController, Platform } from '@ionic/angular';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';

import { DataService } from'src/app/services/data.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authState$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public http: HttpClient,
    private loader: LoadingService,
    private menuCtrl: MenuController,
    private storage: StorageService,
    private platform: Platform,
    private zone: NgZone,
    public data: DataService,
  ) 
  { 
    this.platform.ready().then( _ => this.checkToken())
  }

  private checkToken() {
    this.storage.get("token").then(res =>{
      console.log('checkToken', res)
      this.authState$.next(res ? true : false)
    })
  }

  public login(user:any={}) {
    return new Promise(async (res, rej) => {
      this.data.post('user/login', {...user, app_version: this.data.app_version}).then(async (result:any)=>{
        console.log('login result', result)
        if(result.result == 'success' && result.user) {

          if(result.user.role == 'Buddy') result.user.buddy = true;
          else result.user.buddy = false;
          
          this.data.user = result.user;

          await this.data.setToken(result.token);
  
          if(result.manager) this.storage.set('dsm', result.manager);
          if(result.messages == null) result.messages = [];
          this.storage.set('unreadMessages', result.messages.length);
          if(result.user.training_jobs) this.storage.set('jobCount',result.user.training_jobs);

          let support:any = {}
          if(result.support_number) support.number = result.support_number;
          if(result.support_email) support.email = result.support_email;
          this.storage.set('support', support);
          this.storage.set("user", result.user);
          this.authState$.next(true);
          res(true)
        } 
        else res(false);
      }).catch((e)=>{
        rej(e)
      })
    })
  }

  // autologin() {
  //   return new Promise(async (res, rej) => {
  //     this.loader.present();

  //     let token = await this.storage.get('token');
  //     let httpOptions = {
  //       headers: new HttpHeaders ({
  //         'X-Auth-Token': token ? token : ''
  //       })
  //     }
  //     this.http.get(this.data.base_url+"user/login", httpOptions).pipe(timeout(3000)).subscribe((result:any)=>{
  //       if(result.result == 'success' && result.user) {
  //         this.data.user = result.user;
  //         this.storage.set("user", result.user);

  //         if(result.user.token) {
  //           this.storage.set('token',result.user.token);
  //           this.data.setToken(result.user.token);
  //         }

  //         if(result.DSM) this.storage.set('dsm',result.DSM);
  //         if(result.messages != null) this.storage.set('unreadCNN',result.messages.length);
  //         if(result.user.training_jobs) this.storage.set('jobCount', result.user.training_jobs);
          
  //         let support:any = {}
  //         if(result.support_number) support.number = result.support_number;
  //         if(result.support_email) support.email = result.support_email;
  //         this.storage.set('support', support);

  //         this.loader.dismiss();
  //         this.authState$.next(true);
  //       } 
  //       else{
  //         this.loader.dismiss();
  //       }
  //     }, (err)=>{
  //       this.loader.dismiss();
  //       if(this.data.user) this.authState$.next(true);
  //       else this.authState$.next(false);
  //     });
  //   }); 
  // }


  public logout() {
    return new Promise((res, rej) => {
      this.zone.run(async ()=>{
        await this.menuCtrl.close();
        await this.storage.clear();
        this.authState$.next(false);
        console.error('Logging Out...')

        res(true);
      })
    });
  }

  
  public getAuthStateObserver(): Observable<boolean> {
    return this.authState$.asObservable();
  }

  public isAuthenticated() {
    console.log('isAuthenticated', this.authState$.value)
    return this.authState$.value;
  }
}