import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Events } from 'src/app/services/events.service';
import { SQLiteService } from 'src/app/services/sqlite.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { Markdowns } from 'src/app/models/survey.model';

@Component({
  selector: 'markdowns',
  templateUrl: 'markdowns.html',
  styleUrls: ['./markdowns.scss']
})
export class MarkdownsComponent {

  @Input() fieldSelected;
  @Input() visibleSections;
  @Input() job_id;
  @Input() loaded;
  @Output() selectField : EventEmitter<any> = new EventEmitter()
  @Output() setSection : EventEmitter<any> = new EventEmitter()
  @Output() saveForm : EventEmitter<any> = new EventEmitter()

  @ViewChild(PaginationComponent) paginationComponent: PaginationComponent;
  
  list: Markdowns[] = [];

  component_name = "markdowns Where scantime IS NOT NULL AND"

  hasMarkdowns: boolean = false;
  
  pageLimit:number = 6;
  page:number = 0;
  totalScanned:number = 0;

  constructor(
    public data: DataService,
    private sqlite: SQLiteService,
    private events: Events
  ) {
    this.events.subscribe('markdown_added', () => {
      this.paginationComponent.updateScanCount({status: true, operator : "++"});
    });
  }

  async ngAfterViewInit() {
    await this.sqlite.db.query(`SELECT count(*) as count FROM markdowns WHERE job_id = ${this.job_id}`).then((res)=>{
      if(res.values[0].count > 0) this.hasMarkdowns = true;
    });
  }

  displayPrice(string) {
    return parseFloat(string).toFixed(2);
  }

  async load(event) {
    if(event){
      this.page = event.page
      this.totalScanned = event.totalScanned
    }
    await this.sqlite.db.query(`SELECT * FROM markdowns WHERE job_id = ${this.job_id} AND scantime IS NOT NULL ORDER BY scantime ASC LIMIT ${this.pageLimit} OFFSET ${this.page * this.pageLimit}`).then((res)=>{
      this.list = res.values;
    });
  }
}
